@mixin combined($path) {
  @error ("Sky does not have separate implemented");
}

@mixin separate($path) {
  @font-face {
    font-family: 'SkyTextMedium';
    src: url('#{$path}sky/sky-medium-webfont.woff2') format('woff2'),
      url('#{$path}sky/sky-medium-webfont.woff') format('woff');
  }

  @font-face {
    font-family: 'SkyTextRegular';
    src: url('#{$path}sky/sky-regular-webfont.woff2') format('woff2'),
      url('#{$path}sky/sky-regular-webfont.woff') format('woff');
  }
}
