@use "../abstracts/forms" as f;

/**
 * These are some new utility classes where basic form fields are needed
 *
 * Note that these do not add validation icons or states at present
 *
 * All the files in these classes should not rely on a particular DOM structure
 * and be applied directly to individual elements.
 *
 * Please use components from the form builder library where more complete form elements are needed
 *
 */

.dg-form__label {
  @include f.text--label();
}

.dg-form__field--text {
  @include f.textfield();
}

.dg-form__field--radio {
  @include f.radiofield();
}
