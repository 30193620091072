$_glyphs: (
  contact-us: '\e910',
  more-info: '\e900',
  arrow-down: '\e906',
  arrow-left: '\e907',
  arrow-right: '\e908',
  arrow-up: '\e909',
  close: '\e90a',
  delivery: '\e90b',
  faq: '\e90c',
  hamburger: '\e90d',
  satisfaction: '\e90e',
  tick: '\e90f',
  plan: '\e971',
  calendar: '\e972',
  generic-appliance: '\e911',
  disposal: '\e912',
  installation: '\e913',
  download: '\e973',
  large-cross: '\e974',
  large-tick: '\e975',
  protection: '\e979',
  repair: '\e97a',
  search: '\e97b',
  my-account: '\e97c',
  basket: '\e97d',
  payment-direct-debit: '\e970',
  facebook: '\e97e',
  twitter: '\e97f',
  instagram: '\e981',
  linkedin: '\e982',
  alert: '\e914',
  direct-debit-slim: '\e915',
  lock: '\e916',
);
$icons: map-keys($_glyphs);
$legacy_icons: (
  'error-circle': 'close',
  'tick-circle': 'tick',
);

@mixin base() {
  /* import !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon($icon) {
  $icon: if(
    map-has-key($legacy_icons, $icon),
    map-get($legacy_icons, $icon),
    $icon
  );
  $glyph: map-get($_glyphs, $icon);
  content: '#{$glyph}';
}

:export {
  icons: $icons;
}
