@mixin combined($path) {
  @font-face {
    font-family: 'Source Sans Pro';
    src: url('#{$path}SourceSansPro-SemiBold.eot');
    src: url('#{$path}SourceSansPro-SemiBold.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}SourceSansPro-SemiBold.woff2') format('woff2'),
      url('#{$path}SourceSansPro-SemiBold.woff') format('woff'),
      url('#{$path}SourceSansPro-SemiBold.ttf') format('truetype'),
      url('#{$path}SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold')
        format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('#{$path}SourceSansPro-Regular.eot');
    src: url('#{$path}SourceSansPro-Regular.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}SourceSansPro-Regular.woff2') format('woff2'),
      url('#{$path}SourceSansPro-Regular.woff') format('woff'),
      url('#{$path}SourceSansPro-Regular.ttf') format('truetype'),
      url('#{$path}SourceSansPro-Regular.svg#SourceSansPro-Regular')
        format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('#{$path}SourceSansPro-Bold.eot');
    src: local(''),
      url('#{$path}SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$path}SourceSansPro-Bold.woff2') format('woff2'),
      url('#{$path}SourceSansPro-Bold.woff') format('woff'),
      url('#{$path}SourceSansPro-Bold.ttf') format('truetype'),
      url('#{$path}SourceSansPro-Bold.svg#SourceSansPro') format('svg');
  }
}

@mixin separate($path) {
  @font-face {
    font-family: 'Source Sans Pro SemiBold';
    src: url('#{$path}SourceSansPro-SemiBold.eot');
    src: url('#{$path}SourceSansPro-SemiBold.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}SourceSansPro-SemiBold.woff2') format('woff2'),
      url('#{$path}SourceSansPro-SemiBold.woff') format('woff'),
      url('#{$path}SourceSansPro-SemiBold.ttf') format('truetype'),
      url('#{$path}SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold')
        format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Source Sans Pro Regular';
    src: url('#{$path}SourceSansPro-Regular.eot');
    src: url('#{$path}SourceSansPro-Regular.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}SourceSansPro-Regular.woff2') format('woff2'),
      url('#{$path}SourceSansPro-Regular.woff') format('woff'),
      url('#{$path}SourceSansPro-Regular.ttf') format('truetype'),
      url('#{$path}SourceSansPro-Regular.svg#SourceSansPro-Regular')
        format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
}
