@mixin combined($path) {
  @font-face {
    font-family: 'VAG Rounded LT Pro';
    src: url('#{$path}VAGRoundedLTPro-Bold.eot');
    src: url('#{$path}VAGRoundedLTPro-Bold.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}VAGRoundedLTPro-Bold.woff2') format('woff2'),
      url('#{$path}VAGRoundedLTPro-Bold.woff') format('woff'),
      url('#{$path}VAGRoundedLTPro-Bold.ttf') format('truetype'),
      url('#{$path}VAGRoundedLTPro-Bold.svg#VAGRoundedLTPro-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'VAG Rounded LT Pro';
    src: url('#{$path}VAGRoundedLTPro-Thin.eot');
    src: url('#{$path}VAGRoundedLTPro-Thin.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}VAGRoundedLTPro-Thin.woff2') format('woff2'),
      url('#{$path}VAGRoundedLTPro-Thin.woff') format('woff'),
      url('#{$path}VAGRoundedLTPro-Thin.ttf') format('truetype'),
      url('#{$path}VAGRoundedLTPro-Thin.svg#VAGRoundedLTPro-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'VAG Rounded LT Pro';
    src: url('#{$path}VAGRoundedLTPro-Light.eot');
    src: url('#{$path}VAGRoundedLTPro-Light.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}VAGRoundedLTPro-Light.woff2') format('woff2'),
      url('#{$path}VAGRoundedLTPro-Light.woff') format('woff'),
      url('#{$path}VAGRoundedLTPro-Light.ttf') format('truetype'),
      url('#{$path}VAGRoundedLTPro-Light.svg#VAGRoundedLTPro-Light')
        format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'VAG Rounded LT Pro';
    src: url('#{$path}VAGRoundedLTPro-Black.eot');
    src: url('#{$path}VAGRoundedLTPro-Black.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}VAGRoundedLTPro-Black.woff2') format('woff2'),
      url('#{$path}VAGRoundedLTPro-Black.woff') format('woff'),
      url('#{$path}VAGRoundedLTPro-Black.ttf') format('truetype'),
      url('#{$path}VAGRoundedLTPro-Black.svg#VAGRoundedLTPro-Black')
        format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
}

@mixin separate($path) {
  @font-face {
    font-family: 'VAG Rounded LT Pro Bold';
    src: url('#{$path}VAGRoundedLTPro-Bold.eot');
    src: url('#{$path}VAGRoundedLTPro-Bold.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}VAGRoundedLTPro-Bold.woff2') format('woff2'),
      url('#{$path}VAGRoundedLTPro-Bold.woff') format('woff'),
      url('#{$path}VAGRoundedLTPro-Bold.ttf') format('truetype'),
      url('#{$path}VAGRoundedLTPro-Bold.svg#VAGRoundedLTPro-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'VAG Rounded LT Pro Thin';
    src: url('#{$path}VAGRoundedLTPro-Thin.eot');
    src: url('#{$path}VAGRoundedLTPro-Thin.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}VAGRoundedLTPro-Thin.woff2') format('woff2'),
      url('#{$path}VAGRoundedLTPro-Thin.woff') format('woff'),
      url('#{$path}VAGRoundedLTPro-Thin.ttf') format('truetype'),
      url('#{$path}VAGRoundedLTPro-Thin.svg#VAGRoundedLTPro-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'VAG Rounded LT Pro Light';
    src: url('#{$path}VAGRoundedLTPro-Light.eot');
    src: url('#{$path}VAGRoundedLTPro-Light.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}VAGRoundedLTPro-Light.woff2') format('woff2'),
      url('#{$path}VAGRoundedLTPro-Light.woff') format('woff'),
      url('#{$path}VAGRoundedLTPro-Light.ttf') format('truetype'),
      url('#{$path}VAGRoundedLTPro-Light.svg#VAGRoundedLTPro-Light')
        format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'VAG Rounded LT Pro Black';
    src: url('#{$path}VAGRoundedLTPro-Black.eot');
    src: url('#{$path}VAGRoundedLTPro-Black.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}VAGRoundedLTPro-Black.woff2') format('woff2'),
      url('#{$path}VAGRoundedLTPro-Black.woff') format('woff'),
      url('#{$path}VAGRoundedLTPro-Black.ttf') format('truetype'),
      url('#{$path}VAGRoundedLTPro-Black.svg#VAGRoundedLTPro-Black')
        format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
}
