/* You can add global styles to this file, and also import other style files */
@use "base-styles/base-styles";
@use "base-styles/themes/dg-new" as theme;

.sales {
  @include theme.dg-new();
}
.grecaptcha-badge {
  visibility: hidden;
}
