@mixin combined($path) {
  @font-face {
    font-family: 'Museo Sans';
    src: url('#{$path}MuseoSans-Light.eot');
    src: url('#{$path}MuseoSans-Light.eot?#iefix') format('embedded-opentype'),
      url('#{$path}MuseoSans-Light.woff2') format('woff2'),
      url('#{$path}MuseoSans-Light.woff') format('woff'),
      url('#{$path}MuseoSans-Light.ttf') format('truetype'),
      url('#{$path}MuseoSans-Light.svg#MuseoSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Museo Sans';
    src: url('#{$path}MuseoSans-Regular.eot');
    src: url('#{$path}MuseoSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$path}MuseoSans-Regular.woff2') format('woff2'),
      url('#{$path}MuseoSans-Regular.woff') format('woff'),
      url('#{$path}MuseoSans-Regular.ttf') format('truetype'),
      url('#{$path}MuseoSans-Regular.svg#MuseoSans-Regular') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Museo Sans';
    src: url('#{$path}MuseoSans-Bold.eot');
    src: url('#{$path}MuseoSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$path}MuseoSans-Bold.woff2') format('woff2'),
      url('#{$path}MuseoSans-Bold.woff') format('woff'),
      url('#{$path}MuseoSans-Bold.ttf') format('truetype'),
      url('#{$path}MuseoSans-Bold.svg#MuseoSans-Bold') format('svg');
    font-weight: 600; //todo: this is just copied from 700 below
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Museo Sans';
    src: url('#{$path}MuseoSans-Bold.eot');
    src: url('#{$path}MuseoSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$path}MuseoSans-Bold.woff2') format('woff2'),
      url('#{$path}MuseoSans-Bold.woff') format('woff'),
      url('#{$path}MuseoSans-Bold.ttf') format('truetype'),
      url('#{$path}MuseoSans-Bold.svg#MuseoSans-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
}

@mixin separate($path) {
  @font-face {
    font-family: 'Museo Sans Light';
    src: url('#{$path}MuseoSans-Light.eot');
    src: url('#{$path}MuseoSans-Light.eot?#iefix') format('embedded-opentype'),
      url('#{$path}MuseoSans-Light.woff2') format('woff2'),
      url('#{$path}MuseoSans-Light.woff') format('woff'),
      url('#{$path}MuseoSans-Light.ttf') format('truetype'),
      url('#{$path}MuseoSans-Light.svg#MuseoSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Museo Sans Regular';
    src: url('#{$path}MuseoSans-Regular.eot');
    src: url('#{$path}MuseoSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$path}MuseoSans-Regular.woff2') format('woff2'),
      url('#{$path}MuseoSans-Regular.woff') format('woff'),
      url('#{$path}MuseoSans-Regular.ttf') format('truetype'),
      url('#{$path}MuseoSans-Regular.svg#MuseoSans-Regular') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Museo Sans Bold';
    src: url('#{$path}MuseoSans-Bold.eot');
    src: url('#{$path}MuseoSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$path}MuseoSans-Bold.woff2') format('woff2'),
      url('#{$path}MuseoSans-Bold.woff') format('woff'),
      url('#{$path}MuseoSans-Bold.ttf') format('truetype'),
      url('#{$path}MuseoSans-Bold.svg#MuseoSans-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
}
