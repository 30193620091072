@mixin combined($path) {
  @font-face {
    font-family: 'VAG Rounded W01';
    src: url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.eot');
    src: url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.woff2') format('woff2'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.woff') format('woff'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.ttf') format('truetype'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.svg#VAGRoundedW01-Bold')
        format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'VAG Rounded W01';
    src: url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.eot');
    src: url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.woff2') format('woff2'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.woff') format('woff'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.ttf') format('truetype'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.svg#VAGRoundedW01-Light')
        format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
}

@mixin separate($path) {
  @font-face {
    font-family: 'VAG Rounded W01 Light';
    src: url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.eot');
    src: url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.woff2') format('woff2'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.woff') format('woff'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.ttf') format('truetype'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Light.svg#VAGRoundedW01-Light')
        format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'VAG Rounded W01 Bold';
    src: url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.eot');
    src: url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.eot?#iefix')
        format('embedded-opentype'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.woff2') format('woff2'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.woff') format('woff'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.ttf') format('truetype'),
      url('#{$path}/vag-rounded-w01/VAGRoundedW01-Bold.svg#VAGRoundedW01-Bold')
        format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
}
